/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-modal.largeModal::part(content) {
  --height: 90%;
  position: absolute;
  top: 5%;
  --width: 97%;
  border-radius: 25px;
  display: block;
}

ion-modal.annuaire::part(content) {
  --height: 90%;
  position: absolute;
  top: 5%;
  --width: 90%;
  border-radius: 25px;
  display: block;
}


.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notooltip {
  pointer-events: none;
}

.badge {
  position: absolute;
  top: -0.8em;
  right: -0.2em;
  padding: 8px 8px;
  border-radius: 50%;
  background: red;
  // color: white;
}

.flag {
  width: 30px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid black;
  margin-left: 5px;
  margin-right: 5px;

  // border-style:solid;
  // border-color:black;

  // border: 2px;
}


.bulletNew {
  align-items: left;
  padding: 0px;
  margin: 0px;
  color: red;
}

.statusClosed {
  ion-icon {
    color: green !important;
  }
}

.statusNew {
  ion-icon {
    color: red !important;
  }
}

.statusActive {
  ion-icon {
    color: orange !important;
  }
}

.statusAborted {
  ion-icon {
    color: blue !important;
  }
}

.center {
  text-align: center !important;
}

.notice {
  color: #666666 !important;
}

.errorMsg {
  text-align: center !important;
  color: red !important;
}

.ion-input {
  margin-top: 8px ! important;
  margin-bottom: 0px ! important;

}

.zoom_img {

  align-items: center;
  justify-content: center;

  &::part(content) {
    width: 80%;
    height: 70%;
  }
}

.toastCustom {
  --border-radius: 30px;
}

.ion-label {
  margin-top: 8px ! important;
  margin-bottom: 0px ! important;
  height: 15px ! important
}

.avatar {
  display: inline-block;
    position: relative;
    height: 4em;
    width: 4em;
    overflow: hidden;
    border-radius: 50%;
    img{
      width: 100%;
      max-width: unset;
    }
    ion-icon{
      padding: 4px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
    }
}

.logoEnt {
  height: 2.5em;
  width: 2.5em;
  margin: 4px;
  // border-radius: 50% !important;
}

// ion-accordion-group {
//   background-color: violet !important;
//   color: green !important;

//   ion-accordion {
//     background-color: aquamarine !important;

//     .ion-accordion-toggle-icon {
//       font-size: 30px !important;
//       color: darkcyan !important;
//     }
//   }
// }
.create {
  width: 90%;
  margin: auto;
  text-align: center;
}

.listContainer {
  margin: auto;
  width: 90%;
}

.list {
  margin: auto;
  width: 90%;
  height: 55vh;
  // height: 450px;
  // height: 300px;
  // height: 400px;
  overflow-y: scroll !important;
}

ion-accordion-group {
  .ion-accordion-toggle-icon {
    font-size: 30px !important;
    color: white !important;
  }
}

ion-accordion::part(content) {
  padding-left: 10px;
  background: #5978d4ff;
}

// ion-accordion::part(header) {
//   background-color: orangered;
//   padding: 10px;
// }


requestListColor {
  --french-violet: #6930c3ff;
  --iris: #6448c9ff;
  --slate-blue: #5e60ceff;
  --han-blue: #5978d4ff;
  --united-nations-blue: #5390d9ff;
  --cyan-process: #5cb8dcff;
  --medium-turquoise: #64dfdfff;
  --turquoise: #72efddff;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-collapsed ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --ion-item-background: #5e60ceff;
  --color: white;
}

ion-accordion.accordion-expanding [slot="content"],
ion-accordion.accordion-collapsed [slot="content"],
ion-accordion.accordion-expanded [slot="content"] {
  --ion-item-background: #5978d4ff;
  --color: white;
}

.logo {
  height: 24px;
  width: auto;
}

@media (max-width:450px) {
  .exporter {
    display: none;
  }

  .bigScreen {
    display: none;
  }

  .smallScreen {
    display: flex;
    flex-direction: row;
  }

  .pastille {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .smallScreen.button-inner {
    box-shadow: none;
  }
}

@media(min-width:450px) {
  .smallScreen {
    display: none;
  }
}

@media(max-height:450px) {
  .bigScreen {
    display: none;
  }

  .smallScreen {
    display: flex;
    flex-direction: row;
  }

  .pastille {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .smallScreen.button-inner {
    box-shadow: none;
  }
}

.entete {
  color: lightgray !important;
  background-color: darkblue !important;
}


.netExpoSignature {
  font-family: Heebo-Black !important;
  font-size: 3em;
  color: whitesmoke !important;
}

.eventHelperSignature {
  font-family: Qwigley-Regular !important;
  font-size: 6em;
  font-weight: 600;
  // background: -webkit-linear-gradient(darkblue,blue,darkblue);
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: #72EFDD !important;
}

.eventHelperVerson {
  color: #72EFDD !important;
  margin-top: -40px !important; 
  text-align: right !important; 
  padding-right: 25% !important;
}

// .qrcode {
//   border-radius: 20px !important;
//   overflow: hidden;
//   background-color: transparent !important;
//   margin-top: 10px !important;
// }

@font-face {
  font-family: 'Heebo-Black'; //Police pour NetExpo  
  src: url('./assets/fonts/Heebo-Black.ttf');
}

@font-face {
  font-family: 'Qwigley-Regular'; //Police pour NetExpo  
  src: url('./assets/fonts/qwigley.regular.ttf');
}

img.ng-lazyloaded {
  animation: fadein 1.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
